.footer-basic {
    padding:40px 0;
    color: white;
    background-color:#0e0a03;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-basic ul {
    padding:0;
    list-style:none;
    text-align:center;
    font-size:18px;
    line-height:1.6;
    margin-bottom:0;
}

.footer-basic li {
    padding:0 10px;
}

.footer-basic ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.8;
}

.footer-basic ul a:hover {
    opacity:1;
}

.footer-basic .social {
    text-align:center;
    padding-bottom:25px;
}

.footer-basic .social > a {
    font-size:18px;
    width:40px;
    height:40px;
    line-height:40px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    border:1px solid #ccc;
    margin:0 8px;
    color:inherit;
    opacity:0.75;
}

.footer-basic .social > a:hover {
    opacity:0.9;
}

.footer-basic .copyright {
    margin-top:15px;
    text-align:center;
    font-size:13px;
    color:#aaa;
    margin-bottom:0;
}