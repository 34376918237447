body {
  font-family: "Poppins", Arial, sans-serif;
  background: #000000;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  color: #999999;
}

.main{
  background-color: black;
  display: flex;
  align-items: center;
  overflow: hidden;

}

a:hover{
  text-decoration: none;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(253, 253, 253, 0.3);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6);
}